
.hero-banner{
    position: relative;
    background-color: rgb(255, 255, 255);
    height: 500px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slide{
    background-size: cover;
    background-position: center;
}

.hero-banner .prev, .hero-banner .next{
    position: relative;
    z-index: 10;
    width: 40px;
    background-color: transparent;
    height: auto;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 24px;
    transform: scaleY(200%);
    border: none;
}

.hero-banner .prev:hover, .hero-banner .next:hover{
    color: rgb(255, 75, 75);
    transition-duration: 0.5s;
}

.hero-banner .next{
    position: relative;
    float: right;
} 

.slide{
    position: absolute;
    z-index: 5;
    top: 0;
    width: 100%;
    height: 100%;
}

.slide .btn-container{
    position: absolute;
    bottom: 20px;
    margin-left: 60px;
}

.slide .text-container{
    margin-top: 10px;
    background-color: black;
    opacity: 0.5;
}

@keyframes fadeOutIn{
    0%{
        opacity: 0.5;
    }
    100%{
      opacity: 1;
    }
}

.fadeOutIn{
    animation-name: fadeOutIn;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

@media only screen and (max-width: 768px) {
    .slide .btn-container{
        position: none;
        bottom: 20px;
        display: block !important;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin: 0px auto;
    }
    .btn-container a{
        display: block;
    }
    .slide .text-container{
        margin-top: 20px;
        position: none;
        width: 100%;
        right: 0px !important;
    }
    .slide .text-container h1, .slide .text-container p {
        text-align: center;
    }
}
