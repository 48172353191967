.info-card-container{
    display: block;
    aspect-ratio: 3/4;
    width: 90%;
    background-color: rgb(255, 255, 255);
    margin: 20px;
    border-radius: 10px;
    filter: drop-shadow(-2px 2px 5px #00000071);
}

.info-image-container{
    padding-top: 20px;
    display: flex;
    margin: 0px;
    height: 90%;
    justify-content: center;
    align-items: center;
}

.info-image{
    aspect-ratio: 3/4;
    height: 95%;
}

.info-text-container{
    margin: 0px;
    height: 10%;
    text-align: center;
}

.info-text-container h2{
    margin: 10px;
}

@keyframes lesserJiggle{
    0%{
        opacity: 0;
        transform: rotate(0deg)
    }
    25%{
        transform: rotate(-3deg)
    }
    50%{
        transform: rotate(3deg)
    }
    75%{
      transform: rotate(0deg)
    }
    100%{
        opacity: 1;
    }
  }
  
  
  
  .lesserJiggle, .info-card-container:hover{
    transition-duration: 1000ms;
    animation: lesserJiggle 1s;
    animation-iteration-count: 1;  
  }

  @media only screen and (max-width: 768px) { 
    .info-card-container{
        width: 100%;
        height: 0;
        padding-bottom: 130%;
        margin: 20px;
    }
    
    .info-image{
        aspect-ratio: 3/4 !important;
        width: 85%;
    }
    .info-image-container{
        height: auto;
    }
}