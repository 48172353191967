html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

#root{
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:hover{
  cursor: pointer;
}

.hidden{
  display: none;
}

.nav{
  z-index: 15;
  height: 120px;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 75, 75);
  position: fixed;
  filter: drop-shadow(0px 0px 5px #000000);  
}

@keyframes logoAnimate{
  0%{
      transform: rotate(-8deg)
  }
  4%{
    transform: rotate(4deg)
  }
  8%{
      transform: rotate(-8deg)
  }
  100%{
    transform: rotate(-8deg)
  }

}
.nav .logo{
  height: calc(100% - 20px);
  margin: 10px;
  transform: rotate(-8deg);
  animation-name: logoAnimate;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.14, .75, .2, 1.01);
}
.nav-spacer{
  width: 100%;
  height: 120px;
}
.footer{
  background-color: rgb(255, 75, 75); 
  text-align: center;
  padding: 10px;
}
.footer p{
  color: white;
  padding: 0px;
  margin: 0px;
}
.p-btn{
  width: 200px;
  padding: 14px 5px;
  margin: 5px 10px;
  background-color: rgb(255, 75, 75);
  text-transform: uppercase;
  font-weight: bold;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 0.1vh;
  color: white;
  border: none;
  border-radius: 3px;
  filter: drop-shadow(2px 2px 5px #000000);  
}

.s-btn{
  width: 200px;
  padding: 14px 5px;
  margin: 5px 10px;
  background-color: rgb(134, 223, 250);
  text-transform: uppercase;
  font-weight: bold;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 0.1vh;
  color: black;
  border: none;
  border-radius: 3px;
  filter: drop-shadow(2px 2px 5px #000000);  
}

@keyframes jiggle{
  0%{
      transform: rotate(0deg)
  }
  25%{
      transform: rotate(-10deg)
  }
  50%{
      transform: rotate(10deg)
  }
  75%{
    transform: rotate(0deg)
  }

}

.p-btn:hover{
  background-color: rgb(252, 155, 28);
  transition-duration: 500ms;
  animation: jiggle 1s;
  animation-iteration-count: infinite;
}

.s-btn:hover{
  background-color: rgb(114, 28, 252);
  color: white;
  transition-duration: 500ms;
  animation: jiggle 1s;
  animation-iteration-count: infinite;
}



.inv1-text{
  margin-top: 2.5vw;
  text-align: left !important;
  padding: 0px 30px;
}
.inv2-text{
  text-align: left !important;
}

.overlap-btn-container{
  margin-top: 50px;
}


.horizontal-invert{
  transform: scaleX(-1);
}

details{
  color: white;
}

li{
  list-style: none;
}

li a{
  color: white;
}

@media only screen and (max-width: 768px) {
  .btn-container {
    text-align: center;
  }
  .p-btn{
    width: 80%;
  }
}


#resources{
  margin: 5px;
}
#giveaway-link{
  color:white;
  text-decoration: none;
}
#giveaway-link:hover{
  text-decoration: underline;
}